import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ title, description, path }) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="robots" content="noindex, follow" />
            <meta
                name="Description"
                content={description || "Brahmanandam Hospital: Compassionate care, healing with heart, community wellness center."}
            />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
            />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />

            <meta
                property="og:url"
                content={`${process.env.PUBLIC_URL}${path}`}
            />
            <meta property="og:site_name" content="Brahmanandam Hospital" />
            <meta property="og:title" content={title} />
            <meta
                property="og:description"
                content={description}
            />
            <meta
                property="og:image"
                content={`${process.env.PUBLIC_URL}/img/BRAHMANANDAM VERTICAL.png`}
            />
            <meta property="og:image:width" content="400" />
            <meta property="og:image:height" content="400" />
            <meta property="twitter:card" content="summary" />
            <meta property="twitter:site" content="Brahmanandam Hospital" />
            <meta
                property="twitter:title"
                content={title}
            />
            <meta
                property="twitter:description"
                content={description}
            />
            <meta
                property="twitter:image:src"
                content={`${process.env.PUBLIC_URL}/img/BRAHMANANDAM VERTICAL.png`}
            />
            <meta property="twitter:image:width" content="400" />
            <meta property="twitter:image:height" content="400" />
            <meta property="twitter:creator" content="Brahmanandam Hospital" />
            <link rel="canonical" href={`${process.env.PUBLIC_URL}${path}`} />
        </Helmet>
    );
};

SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    path: PropTypes.string
};

export default SEO;
