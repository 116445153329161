import React from "react";
import { Link } from "react-router-dom";

const ContactAddress = () => {
    return (
        <div className="contact-info-content">
            <div className="info-address">
                <h2 className="title">
                    Jamshedpur,
                    <span>Jharkhand</span>
                </h2>
                <p>
                    184, Kagalnagar, Sonari, Near road no. 3, Jamshedpur,
                    Jharkhand – 831011
                </p>
                <a href="mailto:brahmanandamcare@gmail.com">
                    Email: brahmanandamcare@gmail.com
                </a>
            </div>
            <div className="brand-office">
                <div className="info-tem style-two">
                    <h6>Call directly:</h6> 
                    <a href="tel:+918271827999">
                    <p>+91 8271827999</p>
                    </a>
                </div>
                <div className="info-tem">
                    <h6>Hospital Address:</h6>
                    <p>
                    184, Kagalnagar, Sonari, Near road no. 3, Jamshedpur,
                    Jharkhand – 831011
                    </p>
                </div>
                {/* <div className="info-tem mb-0">
                    <h6>Work Hours:</h6>
                    <p>Mon - Sat: 24/7 </p>
                </div> */}
            </div>
        </div>
    );
};

export default ContactAddress;
