import SocialIcon from "../../components/social-icon";
import { Link } from "react-router-dom";
import Newsletter from "../../components/newsletter";
import Logo from "../../components/logo";

const Footer = () => {
    return (
        <footer className="footer-area">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-md-5 col-lg-3 col-xl-3">
                        <div className="widget-item">
                            <div className="about-widget">
                                <Logo
                                    classOption="footer-logo"
                                    image={`${process.env.PUBLIC_URL}/img/BRAHMANANDAM VERTICAL.png`}
                                />
                                <p>
                                    Brahmanandam Hospital: Compassionate care,
                                    healing with heart, community wellness
                                    center.
                                </p>
                                {/* <ul className="widget-contact-info">
                                    <li className="info-address">
                                        <i className="icofont-location-pin"></i>
                                        69 Halsey St, New York, Ny 10002, United
                                        States.
                                    </li>
                                    <li className="info-mail">
                                        <i className="icofont-email"></i>
                                        <a href="mailto://hello@yourdomain.com">
                                            hello@yourdomain.com
                                        </a>
                                    </li>
                                    <li className="info-phone">
                                        <i className="icofont-ui-call"></i>
                                        <a href="tel://(0091)8547632521">
                                            (0091) 8547 632521
                                        </a>
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 offset-md-1 col-lg-3 offset-lg-0 col-xl-3  d-xl-block">
                        <div className="widget-item d-inline-block">
                            <h4 className="widget-title line-bottom">
                                Events / Conferences
                            </h4>
                            <p className="fw-bold">Recent Events</p>
                            <nav className="widget-posts">
                                <ul className="posts-item">
                                    <li>
                                        <Link to="">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Cardiology Conferences
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Neuroscience Conferences
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Infectious Diseases Conference
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                            <p className="fw-bold mt-3">Upcoming Events</p>
                            <nav className="widget-posts">
                                <ul className="posts-item">
                                    <li>
                                        <Link to="">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Health IT Conferences
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Telemedicine Workshops
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-5 col-lg-3 col-xl-3">
                        <div className="widget-item">
                            <h4 className="widget-title line-bottom">
                                Quick Links
                            </h4>
                            <nav className="widget-posts">
                                <ul className="posts-item">
                                    <li>
                                        <Link to="/service-details/1">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Our Services
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/about">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            About Us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Contact us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/career">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Career with Us
                                        </Link>
                                    </li>
                                    {/* <li>
                                        <Link to="/">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Ai offer weekly updates &amp; more.
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Customer should love your web.
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Your site smooth and stunning.
                                        </Link>
                                    </li> */}
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 offset-md-1 col-lg-3 offset-lg-0 col-xl-3">
                        <div className="widget-item">
                            <h4 className="widget-title line-bottom">
                                Reach Us
                            </h4>
                            <div className="widget-newsletter">
                                {/* <p>
                                    Sign up for our mailing list to get latest
                                    updates and offers.
                                </p> */}
                                {/* <Newsletter mailchimpUrl="//devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef" /> */}
                                <ul className="widget-contact-info">
                                    <li className="info-address">
                                        <i className="icofont-location-pin"></i>
                                        <a href="https://www.google.com/maps/place/22%C2%B049'24.6%22N+86%C2%B010'00.1%22E/@22.823513,86.164117,17z/data=!4m4!3m3!8m2!3d22.8235!4d86.1666944?hl=en&entry=ttu">
                                            184, Kagalnagar, Sonari, Near road
                                            no. 3, Jamshedpur, Jharkhand –
                                            831011
                                        </a>
                                    </li>
                                    <li className="info-mail">
                                        <i className="icofont-email"></i>
                                        <a href="mailto:brahmanandamcare@gmail.com">
                                            brahmanandamcare@gmail.com
                                        </a>
                                    </li>
                                    <li className="info-phone">
                                        <i className="icofont-ui-call"></i>
                                        <a href="tel:+918271827999">
                                            +91 8271827999
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="widget-social-icons">
                                <SocialIcon
                                    path="https://www.facebook.com/BrahmanandamHospital/"
                                    icon="icofont-facebook"
                                />
                                <SocialIcon
                                    path="https://www.instagram.com/brahmanandamhospitals/"
                                    icon="icofont-instagram"
                                />
                                <SocialIcon
                                    path="https://www.linkedin.com/company/brahmanandamhospital/"
                                    icon="icofont-linkedin"
                                />
                                <SocialIcon
                                    path="https://www.youtube.com/channel/UCvaDkyEXks9F_XNT10qrEJg"
                                    icon="icofont-youtube-play"
                                />
                                <SocialIcon
                                    path="https://twitter.com/Brahmanandam_H"
                                    icon="icofont-twitter"
                                />
                                <SocialIcon
                                    path="https://twitter.com/Brahmanandam_H"
                                    icon="icofont-x"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-sm-12">
                            <div className="widget-copyright">
                                <p>
                                    &copy; 2024{" "}
                                    <span className="text-uppercase">
                                        Brahmanandam Hospital
                                    </span>
                                    <i className="icofont-heart-alt"></i> by{" "}
                                    <a
                                        target="_blank"
                                        href="https://www.kgt.solutions/"
                                        rel="noreferrer"
                                    >
                                        KGT Solutions
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

