import React from "react";
import Button from "../../components/button";
import CareerData from "../../data/career.json";
import WhyUs from "../../data/why-us.json";
import { LightgalleryItem, LightgalleryProvider } from "react-lightgallery";
import { Link } from "react-router-dom";
import { flatDeep, slugify, containsObject } from "../../utils";
import AboutAddress from "../../components/about-address";

const CareerContainer = () => {
    return (
        <div className="about-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <p className="text-pra" data-aos="fade-up">
                            {CareerData[0].pageTitle}
                        </p>
                        <div
                            className="service-list-content"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <h4 className="title">{CareerData[1].title}</h4>
                            {CareerData[1].excerpt.map((single, i) => {
                                return (
                                    <div
                                        key={i}
                                        className="desc"
                                        dangerouslySetInnerHTML={{
                                            __html: single,
                                        }}
                                    />
                                );
                            })}
                            <div
                                className="service-list"
                                data-aos="fade-up"
                                data-aos-duration="1200"
                            >
                                <ul>
                                    {WhyUs.map((single, i) => {
                                        return (
                                            <>
                                                <h5 key={i}>{single.title}</h5>
                                                <p key={i}>{single.excerpt}</p>
                                            </>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div
                            className="office-center-content"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <h4 className="title">{CareerData[2].title}</h4>
                            {CareerData[2].excerpt.map((single, i) => {
                                return (
                                    <div
                                        key={i}
                                        className="desc"
                                        dangerouslySetInnerHTML={{
                                            __html: single,
                                        }}
                                    />
                                );
                            })}
                            <div className="row">
                                <div className="col-md-6" data-aos="fade-up">
                                    <div className="gallery-item mb-30">
                                        <LightgalleryProvider>
                                            <LightgalleryItem
                                                group="any"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    CareerData[3].gallery
                                                        .imageOne
                                                }
                                            >
                                                <div className="thumb">
                                                    <div className="lightbox-image">
                                                        <img
                                                            src={
                                                                process.env
                                                                    .PUBLIC_URL +
                                                                CareerData[3]
                                                                    .gallery
                                                                    .imageOne
                                                            }
                                                            alt="gallery"
                                                        />
                                                    </div>
                                                    <div className="overlay">
                                                        <i className="icofont-plus"></i>
                                                    </div>
                                                </div>
                                            </LightgalleryItem>
                                        </LightgalleryProvider>
                                    </div>
                                </div>
                                <div
                                    className="col-md-6"
                                    data-aos="fade-up"
                                    data-aos-duration="600"
                                >
                                    <div className="gallery-item mb-30">
                                        <LightgalleryProvider>
                                            <LightgalleryItem
                                                group="any"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    CareerData[3].gallery
                                                        .imageTwo
                                                }
                                            >
                                                <div className="thumb">
                                                    <div className="lightbox-image">
                                                        <img
                                                            src={
                                                                process.env
                                                                    .PUBLIC_URL +
                                                                CareerData[3]
                                                                    .gallery
                                                                    .imageTwo
                                                            }
                                                            alt="gallery"
                                                        />
                                                    </div>
                                                    <div className="overlay">
                                                        <i className="icofont-plus"></i>
                                                    </div>
                                                </div>
                                            </LightgalleryItem>
                                        </LightgalleryProvider>
                                    </div>
                                </div>
                                <div
                                    className="col-md-12"
                                    data-aos="fade-up"
                                    data-aos-duration="1200"
                                >
                                    <div className="gallery-item">
                                        <LightgalleryProvider>
                                            <LightgalleryItem
                                                group="any"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    CareerData[3].gallery
                                                        .imageThree
                                                }
                                            >
                                                <div className="thumb">
                                                    <div className="lightbox-image">
                                                        <img
                                                            src={
                                                                process.env
                                                                    .PUBLIC_URL +
                                                                CareerData[3]
                                                                    .gallery
                                                                    .imageThree
                                                            }
                                                            alt="gallery"
                                                        />
                                                    </div>
                                                    <div className="overlay">
                                                        <i className="icofont-plus"></i>
                                                    </div>
                                                </div>
                                            </LightgalleryItem>
                                        </LightgalleryProvider>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="office-address-content"
                                data-aos="fade-up"
                                data-aos-duration="1200"
                            >
                                <Button
                                    path={process.env.PUBLIC_URL + "/contact/"}
                                    classOption="book-now-btn"
                                    text="apply now"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CareerContainer;

