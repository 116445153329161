import React from "react";

const GoogleMap = () => {
    return (
        <iframe
            title="Brahmanandam Hospital"
            className="contact-map"
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3677.431683745679!2d86.16411747476441!3d22.823513123728375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjLCsDQ5JzI0LjYiTiA4NsKwMTAnMDAuMSJF!5e0!3m2!1sen!2sin!4v1710572429000!5m2!1sen!2sin"
            aria-hidden="false"
        ></iframe>
    );
};

export default GoogleMap;
