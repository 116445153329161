import React, { useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";


const ContactForm = () => {
    const { register, errors, handleSubmit } = useForm({
        mode: "onBlur",
    });

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formSubmissionFailed, setFormSubmissionFailed] = useState(false);

    const onSubmit = (data) => {
        // Here you can handle form submission logic, e.g., sending data to a server
        console.log(data);
        const options = {
            publicKey: 'LqN5Hz1aOZ_fsKTEE'
        }
        emailjs.send("service_late45m", "template_6gjnj3p", data, options).then(
            (result) => {
                console.log(result, "result");
              setFormSubmitted(true);
              setTimeout(() => {
                setFormSubmitted(false);
              }, 5000);
            },
            (error) => {
                console.log(error, "error");
              setFormSubmissionFailed(true);
              setTimeout(() => {
                setFormSubmissionFailed(false);
              }, 7000);
            }
          ).catch((error) => {
            console.log(error, "catch");
            setFormSubmissionFailed(true);
              setTimeout(() => {
                setFormSubmissionFailed(false);
              }, 7000);
          });

        // For this example, let's just set formSubmitted to true
        // setFormSubmitted(true);
    };

    return (
        <form
            className="contact-form-wrapper"
            onSubmit={handleSubmit(onSubmit)}
            // action="https://getform.io/f/a17a2715-d7ee-4ac4-8fcb-12f1eed43b2c"
            method="POST"
            data-aos="fade-up"
            data-aos-duration="1200"
        >            
            <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            ref={register({ required: "Name is required" })}
                        />
                        {errors.name && <p>{errors.name.message}</p>}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="email"
                            name="email"
                            placeholder="Email Address"
                            ref={register({
                                required: "Email is required",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "Invalid email address",
                                },
                            })}
                        />
                        {errors.email && <p>{errors.email.message}</p>}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="subject"
                            placeholder="Subject (optional)"
                            ref={register}
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group mb-0">
                        <textarea
                            name="message"
                            rows="5"
                            placeholder="Write your message here"
                            ref={register({
                                required: "Message is required",
                            })}
                        ></textarea>
                        {errors.message && <p>{errors.message.message}</p>}
                    </div>
                </div>
                <div className="col-md-12 text-center">
                    <div className="form-group mb-0">
                        <button
                            className="btn btn-theme btn-block"
                            type="submit"
                        >
                            Send Message
                        </button>
                        {formSubmitted && (
                            <p style={{ color: "green" }}>
                                Form submitted successfully!
                            </p>
                        )}
                        {formSubmissionFailed && (
                            <p style={{ color: "red" }}>
                                Something Went Wrong!
                            </p>
                        )}
                        {Object.keys(errors).length > 0 && (
                            <p style={{ color: "red" }}>
                                Please fill in all mandatory fields.
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ContactForm;
