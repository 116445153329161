import React from "react";
import Accordion, {
    AccordionItem,
    AccordionTitle,
    AccordionContent,
} from "../accordion";

const AccordionWrap = () => {
    return (
        <Accordion classOption="accordion-style2 no-bg">
            <AccordionItem id="one">
                <AccordionTitle id="one">
                    What are the signs of a heart attack, and when should I seek
                    medical help?
                </AccordionTitle>
                <AccordionContent id="one">
                    Signs of a heart attack can include chest pain or
                    discomfort, shortness of breath, nausea, lightheadedness,
                    and pain or discomfort in the arms, back, neck, jaw, or
                    stomach. If you experience these symptoms, especially if
                    they are severe or persistent, seek emergency medical
                    attention immediately.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="two">
                <AccordionTitle id="two">
                    How can I lower my risk of developing diabetes?
                </AccordionTitle>
                <AccordionContent id="two">
                    Lowering your risk of diabetes involves adopting healthy
                    lifestyle habits such as maintaining a balanced diet,
                    engaging in regular physical activity, maintaining a healthy
                    weight, and avoiding tobacco use. Additionally, it is
                    essential to monitor your blood sugar levels regularly,
                    especially if you have risk factors such as a family history
                    of diabetes or being overweight.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="three">
                <AccordionTitle id="three">
                    What are some effective ways to manage stress and improve
                    mental health?
                </AccordionTitle>
                <AccordionContent id="three">
                    Effective stress management techniques include practicing
                    relaxation techniques such as deep breathing, meditation, or
                    yoga, engaging in regular physical activity, getting
                    adequate sleep, maintaining a healthy diet, setting
                    realistic goals, seeking social support, and scheduling
                    regular downtime for hobbies and activities you enjoy.
                    Additionally, seeking professional help from a therapist or
                    counselor can be beneficial for addressing underlying mental
                    health issues
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="four">
                <AccordionTitle id="four">
                    How can I prevent the spread of infectious diseases such as
                    the flu or COVID-19?{" "}
                </AccordionTitle>
                <AccordionContent id="four">
                    To prevent the spread of infectious diseases, it is
                    essential to practice good hygiene habits such as washing
                    your hands frequently with soap and water for at least 20
                    seconds, using hand sanitizer when soap and water are not
                    available, avoiding close contact with sick individuals,
                    wearing a mask in crowded or public settings, covering your
                    mouth and nose with a tissue or your elbow when coughing or
                    sneezing, and staying home when you are sick. Additionally,
                    getting vaccinated against preventable infectious diseases
                    such as the flu and COVID-19 can provide added protection.
                </AccordionContent>
            </AccordionItem>
        </Accordion>
    );
};

export default AccordionWrap;
